import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Wrapper from "../components/Wrapper";
import useSiteMetadata from "./SiteMetadata";

import "../styles/style.scss";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet defaultTitle={title} titleTemplate="%s | Crossfit Tegen">
        <html lang="en" />
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix("/")}img/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon.png`} sizes="32x32" />
        <link rel="icon" type="image/png" href={`${withPrefix("/")}img/favicon.ico`} sizes="16x16" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Header siteTitle={title} />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </>
  );
};

export default TemplateWrapper;
