import React, { useState } from "react";
import { Link } from "gatsby";
import { FiMenu, FiX, FiMoon, FiSun, FiChevronDown } from "react-icons/fi";
import {
  Button,
  Box,
  Flex,
  Heading,
  Text,
  IconButton,
  Stack,
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

const MenuItems = ({ children, ...props }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} {...props}>
    {children}
  </Text>
);

const Header = ({ siteTitle }) => {
  const { toggleColorMode } = useColorMode();
  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const icon = useColorModeValue(<FiMoon />, <FiSun />);

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      width="100%"
      zIndex="1"
      pos="fixed"
      as="header"
      align="center"
      flexDirection="column"
      wrap="wrap"
      padding="1rem"
      bg={bg}
      color={color}
    >
      <Box maxW="7xl" width="100%">
        <Flex width="100%" justify="center" align="center">
          <Link to="/">
            <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
              {siteTitle}
            </Heading>
          </Link>
        </Flex>
        <Flex
          as="nav"
          align="center"
          justify={{ base: "flex-end", md: "center" }}
          wrap="wrap"
          width="100%"
          padding="1rem"
          bg={bg}
          color={color}
        >
          <Box display={{ base: "flex", md: "none" }} justifySelf="flex-end" cursor="pointer" onClick={handleToggle}>
            {!show ? <FiMenu size={24} /> : <FiX size={24} />}
          </Box>

          <Box
            display={{ base: show ? "block" : "none", md: "flex" }}
            width={{ base: "full", md: "auto" }}
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
          >
            <Links />
            <Box display={{ base: show ? "block" : "none", md: "block" }} mt={{ base: 4, md: 0 }}>
              <Stack isInline spacing={4} align="center">
                <Link to="/bli-medlem">
                  <Button bg="transparent" border="1px">
                    Bli medlem
                  </Button>
                </Link>
                <IconButton aria-label="Toggle theme" icon={icon} onClick={toggleColorMode} />
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

const Links = () => (
  <>
    <Link to="/">
      <MenuItems>Hem</MenuItems>
    </Link>

    <Link to="/boka">
      <MenuItems>Boka pass</MenuItems>
    </Link>

    <Link to="/nyheter">
      <MenuItems>Nyheter</MenuItems>
    </Link>

    <Menu isLazy>
      <MenuButton mt={{ base: 4, md: 0 }} mr={6} display="block">
        <Flex alignItems="center">
          Vi erbjuder <FiChevronDown style={{ marginLeft: "0.5rem" }} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to="/pt">
          PT
        </MenuItem>
        <MenuItem as={Link} to="/naprapat">
          Naprapat
        </MenuItem>
        <MenuItem as={Link} to="/kurser">
          Kurser
        </MenuItem>
      </MenuList>
    </Menu>

    <Menu isLazy>
      <MenuButton mt={{ base: 4, md: 0 }} mr={6} display="block">
        <Flex alignItems="center">
          Om oss <FiChevronDown style={{ marginLeft: "0.5rem" }} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to="/om">
          Om Tegen
        </MenuItem>
        <MenuItem as={Link} to="/coacher">
          Coacher
        </MenuItem>
      </MenuList>
    </Menu>

    <Link to="/kontakt">
      <MenuItems>Kontakt</MenuItems>
    </Link>
  </>
);

export default Header;
