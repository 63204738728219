import React from "react";
import { Flex, Text, Image, Link } from "@chakra-ui/react";

import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";

const Footer = () => (
  <Flex as="footer" direction="column" alignItems="center" justifyContent="center" py="3rem">
    <Flex alignItems="center" justifyContent="center">
      <Link href="https://www.facebook.com/crossfittegen/" p="1rem">
        <Image src={facebook} alt="Crossfit tegen facebook" />
      </Link>
      <Link href="https://www.instagram.com/crossfittegen/" p="1rem">
        <Image src={instagram} alt="Crossfit tegen instagram" />
      </Link>
    </Flex>
    <Text>Copyright © {new Date().getFullYear()} - CrossFit Tegen</Text>
  </Flex>
);

export default Footer;
