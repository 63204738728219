import React from "react";
import { Box, useColorMode } from "@chakra-ui/react";

const Wrapper = ({ children }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: "white", dark: "gray.900" };
  const color = { light: "gray.900", dark: "white" };
  const heightOfNavbar = "140px";
  const heightOfNavbarSmall = "120px";

  return (
    <Box pt={{ base: heightOfNavbarSmall, md: heightOfNavbar }} bg={bgColor[colorMode]} color={color[colorMode]}>
      {children}
    </Box>
  );
};

export default Wrapper;
